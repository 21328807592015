$font-family-sans-serif: 'Open Sans', sans-serif;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$link-color: #d60915;
$link-hover-color: darken($link-color, 15%);

// NOTE: btn-primary is overridden below (in `@include themed()`) to support dark mode
$theme-colors: (
  primary: $link-color,
  light: $white,
);

$list-group-active-bg: $link-hover-color;
$list-group-active-border-color: darken($list-group-active-bg, 10%);

$transition-collapse: none;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import './styles/theme.scss';

.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border-radius: 2px;

  text-transform: uppercase;
  font-weight: bold;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    border-bottom: 2px solid;
    border-bottom-color: darken($value, 15%);
  }
}

@include themed() {
  .btn-primary {
    background-color: t('text-link');
    border-color: t('text-link');
    border-bottom-color: t('bs-btn-primary-bottom-border');
  }

  .btn-outline-primary {
    border-color: t('text-link');
    color: t('text-link');

    &.tinted {
      background-color: t('bs-btn-outline-bg');
    }

    &:hover {
      color: white;
      background-color: t('text-link');
    }
  }
}

.btn-light {
  text-transform: none;
  font-weight: normal;
  border: none;
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    border-width: 2px;
    border-radius: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@include themed() {
  .btn-link {
    color: t('text-link');

    &:hover {
      color: t('text-link-hover');
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-plain-text-#{$color} {
    @extend .btn-link;

    font-weight: normal;
    text-transform: none;
    text-align: left;
    color: $value;
    padding: 0.25rem 0.5rem;
    padding-right: 2rem;

    &:hover {
      color: darken($value, 15%);
    }
  }
}

@font-face {
  font-family: 'Mr. Eaves';
  src: url('./assets/fonts/Mr-Eaves-small-caps.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vollkorn';
  src: url('./assets/fonts/Vollkorn-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bookmania';
  src: url('./assets/fonts/Bookmania-Regular/font.woff2') format('woff2'),
    url('./assets/fonts/Bookmania-Regular/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bookmania';
  src: url('./assets/fonts/Bookmania-BoldItalic/font.woff2') format('woff2'),
    url('./assets/fonts/Bookmania-BoldItalic/font.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bookmania';
  src: url('./assets/fonts/Bookmania-RegularItalic/font.woff2') format('woff2'),
    url('./assets/fonts/Bookmania-RegularItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@import './styles/variant-tooltip.scss';
@import './styles/striped-table.scss';
@import './styles/modal.scss';
@import './styles/navbar.scss';
@import './components/Filters/Filters.scss';

@include themed() {
  body {
    background: t('bg');
    color: t('text');
  }

  .modal-content {
    background: t('bg');
  }

  .card {
    background-color: t('bs-bg-card');
  }
}

.modal > div {
  transition: backdrop-filter 0.15s ease-in-out;
  backdrop-filter: blur(0px);

  &.modal-showing {
    backdrop-filter: blur(3px);
  }
}
